import React from "react"
import { Link } from "gatsby"
import { useUrlSearchParams } from "use-url-search-params";
import CookiesModul from "../components/cookiesmodul"

import LayoutNoNav from "../components/layout_no_nav"
import SEO from "../components/seo"
import Footer from "../components/footer"
import landlord_header from "../images/landlord_header.png"
import logo from "../images/logo.svg"

import wave from "../images/wave.svg"

const AgencySuccess = () => {

  const [params] = useUrlSearchParams({});

  return (
    <LayoutNoNav>
      <SEO
        title="Partner Success"
        description="Partner Success"
        image={landlord_header}
      />

      <section className="container mx-auto mt-32 p-10">
        <div className="w-full text-center">
          <h2 className="text-2xl md:text-5xl font-bold text-gray-700 leading-none">
            Message Sent 
          </h2>
          <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-2xl mx-auto mt-3">
            Thank you for submit your enquiry. A message has been sent and an agent will be in contact with you ASAP.
          </p>
        </div>
      </section>

      <img classNameName="w-full" src={wave} alt="" />

      <footer className="container mx-auto">
        <div className="px-4 md:px-10 py-24 text-center">
          <img className="h-4 mx-auto" src={logo} alt="" />
          <p className="w-full text-xs text-gray-400 mt-4">
            Copyright © 2021 Flow Living (PTY) Ltd. All rights reserved. <br />
            <a 
            href="/termsofuse" 
            target="_blank"
            className="underline mx-1"
          >
            Terms of use
          </a>
            <a 
              href="/privacypolicy" 
              target="_blank"
              className="underline mx-1">
              Privacy Policy
            </a>
          </p>
        </div>
      </footer>
      <CookiesModul />
    </LayoutNoNav>
  )
}

export default AgencySuccess
